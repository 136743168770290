import React, { useEffect } from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import HeroBlob from "../images/hero_banner_blob.svg"
import breakPoints from "../APIs/devices"
import { navigate } from "gatsby"

const Root = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  flex-direction: column;
`

const HeroM = styled.div`
  z-index: -1;
  position: absolute;
  top: -20%;
  left: -70%;
  background-image: ${`url(${HeroBlob})`};
  width: 190%;
  height: 190%;
  border: none;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: contain;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: none;
  }
`

const HeroD = styled.div`
  display: none;
  z-index: -1;
  position: absolute;
  top: -20%;
  left: -70%;
  background-image: ${`url(${HeroBlob})`};
  width: 190%;
  height: 190%;
  border: none;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: contain;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: initial;
    top: -40%;
    left: -10%;
    width: 100%;
    height: 140%;
  }
`

const Header = styled.h1`
  text-align: center;
  @media only screen and (min-width: ${breakPoints.lg}) {
    font-size: 4.5vw;
  }
`

const ThankYouPage = () => {
  useEffect(() => {
    setTimeout(() => navigate("/"), 2500)
  }, [])

  return (
    <>
      <SEO title="Thank You" />
      <Root>
        <HeroM />
        <HeroD />
        <Header>Thank you for your purchase!</Header>
      </Root>
    </>
  )
}

export default ThankYouPage
